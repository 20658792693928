import React from 'react'
import { useState, useEffect, useRef } from "react";
import styles from "./Distributions.module.css";
import { Table, Button, Tooltip, Badge, Progress, Alert } from "antd";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import { useDispatch, useSelector } from "react-redux";
import { LinuxOutlined, WindowsFilled } from "@ant-design/icons";
import { IconDownloadFile } from "../../icons";
import { IconInfoDetail } from "../../icons/IconInfoDetail";

import {
  agentsDownload,
  //agentsDownload,
  getDistributionsAgentDetails,
  getDistributionsAgentList,
} from "../../store/distributions";

import {
  getDistributionsAgentData,
  getAgentLoaders,
  getAgentNextPage,
  getAgentsDownloadData,
} from "../../store/distributions/selectors";
import { baseUrl, typeFileApp, viewExtension, getExtension } from "./utils";
import { bytesToSize } from "../../utils/converterBytes";

export const DistributionsAgentTable = ({
  createColumn,
  //downloadSoftFile,
  setIsModalViewOpenAgent,
}) => {
  const dispatch = useDispatch();
  const isLoadingAgent = useSelector(getAgentLoaders);
  const agentsData = useSelector(getDistributionsAgentData);
  const lastItemAgent = useRef();
  const observerAgent = useRef();
  const [needToLoad, setNeedToLoad] = useState(false);
  const nextPage = useSelector(getAgentNextPage);
  //const [progressDownload, setProgressDownload] = useState(0);
  //const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [downloadError, setDownloadError] = useState(false)
  const agentsDownloadData = useSelector(getAgentsDownloadData)
  const columnsAgent = [
    createColumn(
      "Версия ПО Агента",
      "major_version",
      (release, row) => (<div className={styles.versionColumnWrapper}>
        <div className={styles.versionColumn}>
          {release}
          <Tooltip
            title={<span className={styles.primaryText}>Release notes</span>}
            color="#FFFFFF"
          >
            <Button
              size="small"
              type="text"
              style={{ background: "none" }}
              icon={<IconInfoDetail className={styles.icon} />}
              onClick={() => {
                dispatch(getDistributionsAgentDetails(row?.major_version_id));
                setIsModalViewOpenAgent(true);
              }}
            ></Button>
          </Tooltip>
        </div>
        <span>  {row['release_date']}</span>
      </div>
      ),
      50,
      false
    ),
    createColumn(
      "Тип ОС",
      "releases",
      (releases, row) =>
        releases && releases.length > 0 ? (

          <div
            className={styles.typeColumnWrapper}

          >
            {releases?.map((release) => (
              <div className={styles.spaceBetweenJustified} key={release.release_id + "agentSoft"}>
                <div
                  className={styles.centerAlignedItemsWithGap}

                >
                  {release?.os_type === "linux" ? (
                    <>
                      <LinuxOutlined className={styles.iconSistem} />{" "}
                      {capitalizeFirstLetter(release?.os_type)}
                    </>
                  ) : release?.os_type === "windows" ? (
                    <>
                      <WindowsFilled className={styles.iconSistem} />{" "}
                      {capitalizeFirstLetter(release?.os_type)}
                    </>
                  ) : null}
                  {
                    <Badge
                      count={viewExtension(release?.file_name)}
                      color="#1677ff"
                    />
                  }
                  <span className={styles.secondaryText}>
                    ({bytesToSize(release?.release_size)})
                  </span>

                </div>
                <Tooltip
                  title={
                    <span className={styles.primaryText}>Скачать файл</span>
                  }
                  color="#FFFFFF"
                >
                  <Button
                    size="small"
                    type="text"
                    className={styles.downloadButton}
                    icon={<IconDownloadFile className={styles.icon} />}
                    onClick={() => {
                      handleClickDownload(row.major_version_id, release?.release_id, release?.file_name, release)
                    }}
                    title='Скачать файл'
                  ></Button>
                </Tooltip>
              </div>
            ))}
          </div>

        ) : (
          ""
        ),
      500,
      false
    ),

  ];

  const fetchData = (pagination = false) => {
    // let filters = {};
    // if (search) {
    //   filters = {
    //     search: search,
    //   };
    // }

    dispatch(
      getDistributionsAgentList({
        page: pagination ? nextPage : 1,
        pagination: pagination,
      })
    );
  };

  useEffect(() => {
    if (isLoadingAgent) return;
    if (observerAgent?.current) observerAgent?.current.disconnect();
    let callback = function (
      entries
      //observer
    ) {
      if (entries[0].isIntersecting && nextPage && agentsData?.length > 0) {
        setNeedToLoad(true);
      }
    };

    observerAgent.current = new IntersectionObserver(callback);
    observerAgent.current.observe(lastItemAgent?.current);
  }, [isLoadingAgent]);

  useEffect(() => {
    (async () => {
      if (needToLoad && nextPage) {
        fetchData(true);
        setNeedToLoad(false);
      }
    })();
  }, [needToLoad]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    const timerRefresh = setTimeout(fetchData, 500);
    return () => {
      clearTimeout(timerRefresh);
    };

  }, []);
  const handleClickDownload = (majorId, releaseId, fileName, release) => {
    dispatch(agentsDownload({ openModal: true, release: release, progress: 0 }))

    const apiKey = sessionStorage.getItem('accessTokenPC')

    const protocol = baseUrl()
    fetch(`${protocol}/api/v1/pim/agent-release/${majorId}/download/${releaseId}/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${apiKey}`
      }
    }
    )

      .then(response => {
        if (!response.ok) {
          return response.text().then(text => { throw new Error(JSON.parse(text)?.detail || 'Ошибка сервера!') })

        }
        const filename = fileName
        const contentLength = response.headers.get('Content-Length');
        // Получаем ReadableStream
        const reader = response.body.getReader();
        const chunks = [];
        let receivedLength = 0;
        // Функция для чтения данных
        function readStream() {

          return reader.read().then(({ done, value }) => {
            if (done) {
              // Преобразование собранных чанков в Blob
              const blob = new Blob(chunks, { type: typeFileApp(getExtension(filename)) },);
              const url = URL.createObjectURL(blob);
              // Создание ссылки для скачивания
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              a.download = filename; // Название скачиваемого файла
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
              return;
            }

            // Сохранение чанка данных
            chunks.push(value);
            receivedLength += value.length;
            //setProgressDownload(Math.floor((receivedLength / contentLength) * 100))
            dispatch(agentsDownload({ openModal: true, release: release, progress: Math.floor((receivedLength / contentLength) * 100) }))
            // Продолжаем чтение
            return readStream();
          });
        }

        return readStream();
      })
      .catch(error => {
        setDownloadError(error?.message || error?.Error)
      });
  }

  return (
    <div className={styles.tableWrapper}>
      <div className={styles.downloadFileWrapper} style={{ display: agentsDownloadData?.openModal ? 'block' : "none" }}>
        <div className={styles.fileDownloader}>
          <span>Версия релиза: {agentsDownloadData.release?.release_version}</span>
          <span>Размер файла: {bytesToSize(agentsDownloadData?.release?.release_size)}</span>
          <span>Файл:  <Badge
            count={viewExtension(agentsDownloadData?.release?.file_name)}
            color="#1677ff"
          /></span>
        </div>
        <div className={styles.fileDownloaderProgressBarWrapper}>
          {downloadError && <Alert message={downloadError} type="error" />}
          <Progress percent={agentsDownloadData.progress} steps={15} size={[25, 20]} />
          {agentsDownloadData.progress === 100 && <span className={styles.successDownloadMessage}>Файл успешно загружен!</span>}
          {(agentsDownloadData.progress === 100 || downloadError) && <Button type="primary"
            onClick={() => { dispatch(agentsDownload({ progress: 0, openModal: false, release: null })); setDownloadError(false) }}
          >Закрыть</Button>}
        </div>
      </div>
      <div>

      </div>
      <Table
        loading={{
          indicator: <PreloaderNew />,
          spinning: isLoadingAgent,
        }}
        columns={columnsAgent}
        rowKey={(obj) => obj.major_version}
        size="small"
        style={{ opacity: agentsDownloadData?.openModal ? 0 : 1 }}
        pagination={false}
        className={`${styles.table} ${styles.table_header}`}
        dataSource={agentsData}
        locale={{
          triggerDesc: "сортировать по убыванию",
          triggerAsc: "сортировать по возрастанию",
          cancelSort: "сбросить",
        }}
      />
      <div ref={lastItemAgent} className={styles.lastEl} />
    </div>
  );
};

