import React from 'react'
import { useEffect, useState } from "react";
import styles from "./OrgArea.module.css";
import { Tabs, Space, Button, Input, ConfigProvider, Select } from "antd";
import WithoutAuthorization from "../../components/WithoutAuthorization";
import AssetListNew from "./AssetListNew";
import UserListNew from "./UserListNew";
import { IconFilter } from "../../icons";
import AssetItemModal from "./AssetItemModal";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCitiesData,
  getOrganisationsData,
  getPlatformsData,
} from "../../store/organisation/selectors";
import {
  getCities,
  getOrganisations,
  getPlatforms,
} from "../../store/organisation";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";

function OrgAreaNew({ auth, permissions }) {
  const [selectedTab, setSelectedTab] = useState(tabs[0].key);
  const [filterOpened, setFilterOpened] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const [isFirstLoading, setIsFirstLoading] = useState(true)
  const dispatch = useDispatch();
  //filters
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const cities = useSelector(getCitiesData);
  const platforms = useSelector(getPlatformsData);
  const organisations = useSelector(getOrganisationsData);
  const [cityFilter, setCityFilter] = useState(null);
  const [platformFilter, setPlatformFilter] = useState(null);
  const [orgFilter, setOrgFilter] = useState(null);

  useEffect(() => {

    if (permissions?.full_access || permissions?.read_access) {
      dispatch(getCities());
      dispatch(getPlatforms());
      dispatch(getOrganisations());
    }
  }, [permissions]);

  const clearFilters = () => {
    setSearch("");
    setOrgFilter(null);
    setPlatformFilter(null);
    setCityFilter(null);
  };

  return (
    <>
      {!auth && <div>LOADING</div>}

      {auth && (permissions?.full_access || permissions?.read_access) && (
        <Space direction="vertical" size={20} style={{ width: "100%" }}>
          <Navigate replace={true} to="/" />
          {/* <Title level={3} style={{ margin: 0 }}>Организация</Title> */}
          <div style={{ display: "flex", gap: 12 }}>
            <ConfigProvider
              theme={{
                components: {
                  Tabs: {
                    itemColor: "#8090B8",
                    itemHoverColor: "#333333",
                    itemSelectedColor: "#333333",
                    inkBarColor: "#333333",
                  },
                },
              }}
            >
              <Tabs
                items={tabs}
                activeKey={selectedTab}
                className={styles.tabs}
                onChange={(key) => setSelectedTab(key)}
              />
            </ConfigProvider>
            <div style={{ flexGrow: 1 }}></div>
            {selectedTab === "devices" && (
              <Button
                onClick={() => setFilterOpened((v) => !v)}
                icon={<IconFilter style={{ width: 14 }} />}
                style={{
                  height: 40,
                  width: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexShrink: 0,
                  backgroundColor: "white",
                }}
              ></Button>
            )}
            <Input
              data-testid='seacrh-input'
              prefix={<SearchOutlined style={{ color: "#C1C2C9" }} />}
              placeholder="Поиск"
              onChange={(evt) => {
                setCurrentPage(1);
                setSearch(evt.target.value);
              }}
              value={search}
              suffix={
                <CloseOutlined
                  onClick={() => {
                    setCurrentPage(1);
                    setSearch("");
                  }}
                />
              }
              style={
                search
                  ? {
                      color: "red",
                      width: "356px",
                      height: 40,
                      padding: "12px 16px",
                    }
                  : {
                      color: "black",
                      width: "356px",
                      height: 40,
                      padding: "12px 16px",
                    }
              }
            />
          </div>

          {filterOpened && selectedTab === "devices" && (
            <Space direction="vertical" size={12} style={{ width: "100%" }}>
              <Space className={styles.filters}>
                <Select
                  placeholder="Организация"
                  defaultValue={orgFilter}
                  value={orgFilter}
                  onChange={(value) => {
                    setCurrentPage(1);
                    setOrgFilter(value);
                  }}
                  options={
                    organisations?.map((v) => ({
                      value: v.organisation__name,
                      label: v.organisation__name,
                    })) || []
                  }
                />
                <Select
                  placeholder="Платформа"
                  defaultValue={platformFilter}
                  value={platformFilter}
                  onChange={(value) => {
                    setCurrentPage(1);
                    setPlatformFilter(value);
                  }}
                  options={
                    platforms?.map((v) => ({
                      value: v.platform__name,
                      label: v.platform__name,
                    })) || []
                  }
                />
                <Select
                  placeholder="Город"
                  value={cityFilter}
                  defaultValue={cityFilter}
                  onChange={(value) => {
                    setCurrentPage(1);
                    setCityFilter(value);
                  }}
                  options={
                    cities?.map((v) => ({
                      value: v.city,
                      label: v.city,
                    })) || []
                  }
                />
                {/* <Cascader
                                    placeholder="Подписки"
                                    multiple
                                    maxTagCount="responsive"
                                    options={filterOptions}
                                    disabled={true}
                                />
                                <Cascader
                                    placeholder="Сервис"
                                    multiple
                                    maxTagCount="responsive"
                                    options={filterOptions}
                                    disabled={true}
                                />
                                <Cascader
                                    placeholder="Подключение"
                                    multiple
                                    maxTagCount="responsive"
                                    options={filterOptions}
                                    disabled={true}
                                /> */}
              </Space>
              <Space size={12} style={{ width: "100%", justifyContent: "end" }}>
                <Button
                  type="default"
                  disabled={!orgFilter && !cityFilter && !platformFilter}
                  onClick={() => clearFilters()}
                >
                  Сбросить
                </Button>
                <Button
                  type="primary"
                  onClick={() => setFilterOpened((v) => !v)}
                >
                  Свернуть
                </Button>
              </Space>
            </Space>
          )}

          {selectedTab === "devices" && (
            <AssetListNew
              openModal={setModalItem}
              search={search}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              cityFilter={cityFilter}
              platformFilter={platformFilter}
              orgFilter={orgFilter}
              isFirstLoading={isFirstLoading}
              setIsFirstLoading={setIsFirstLoading}
            />
          )}
          {selectedTab === "users" && <UserListNew />}
          <AssetItemModal
            open={modalItem !== null}
            close={() => setModalItem(null)}
            item={modalItem}
          />
        </Space>
      )}

      {permissions?.full_access === false &&
        permissions?.read_access === false && <WithoutAuthorization />}
    </>
  );
}

const tabs = [
  { key: "devices", label: "Устройства" },
  { key: "users", label: "Пользователи" },
];

export default OrgAreaNew;
