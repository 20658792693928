import React, { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Col,
  DatePicker,
  List,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Spin
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getUrlAssetsDropdown,
  getUrlGraph,
  getUrlKfeedHits,
  setSnFilterUrl
} from "../../store/urlfilter";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import styles from "./UrlFilterNew.module.css";
import dayjs from "dayjs";
import {
  EyeOutlined,
  CalendarOutlined,
  CloseOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  getOrgFallbacksInfo,
  getUrlKfeedHitsData,
  getUrlLoaders,
  getUrlSnFilter,
  getUrlSnDropdown
} from "../../store/urlfilter/selectors";
import { IconWarning } from "../../icons";
import { getRandomId } from "./utils";
import UrlItemModalKfeedResponse from "./UrlItemModalKfeedResponse";
import { Collapse } from "antd";
import { NewGraphKfeed } from "./NewGraphKfeed/NewGraphKfeed";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getCollapsed } from "../../store/sidebar/selectors";

function UrlKfeed() {
  const dispatch = useDispatch();
  const isSidebarCollapsed = useSelector(getCollapsed)

  const calculateYesterday = () => {
    let today = new Date();
    today.setDate(today.getDate() - 1);
    return today;
  };
  const calculateMonth = () => {
    let date = new Date();
    date.setDate(date.getDate() - 30);
    return date;
  };

  const getDaysBorder = () => {
    const today = new Date();
    const thirtyDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30);
    const formatDate = (date) => {
      return date.getDate().toString().padStart(2, '0') + '.' +
        (date.getMonth() + 1).toString().padStart(2, '0') + '.' +
        date.getFullYear();
    };
    let dateBorders = `${formatDate(thirtyDaysAgo)} - ${formatDate(today)}`;
    return dateBorders;
  };


  const [openModal, setOpenModal] = useState(false);
  const isLoader = useSelector(getUrlLoaders);
  const kfeedHitsData = useSelector(getUrlKfeedHitsData);


  const [currentPage, setCurrentPage] = useState(1);
  const snFilterStore = useSelector(getUrlSnFilter);
  const [snFilter, setSnFilter] = useState(null);
  const [openDate, setOpenDate] = useState(false);
  const [url, SetUrl] = useState(null);
  const fallbacksData = useSelector(getOrgFallbacksInfo);
  const [ordering, setOrdering] = useState({
    popularity: null,
    first_seen: null,
    url: null,
    kfeed_hits: null,
    kfeed_secondary_hits: null,
    kfeed_direct_hits: null,
  });



  //фильтры
  const [dateAfterFilter, setDateAfterFilter] = useState(null);
  const [dateBeforeFilter, setDateBeforeFilter] = useState(null);
  const [viewDataFilter, setViewDataFilter] = useState(null);

  //select с поиском
  useEffect(() => {
    dispatch(getUrlAssetsDropdown());
  }, [])
  const kfeedSerials = useSelector(getUrlSnDropdown);
  const kfeedSerialsSelectOptions = kfeedSerials?.map((item) => {
    return { value: item, label: item }
  })


  const fetchDataHits = () => {
    let filters = false;
    if (snFilter || dateAfterFilter || dateBeforeFilter)
      filters = {
        serial_nr: snFilter,
        date_after: dateAfterFilter
          ?.split(" ")[0]
          .split(".")
          .reverse()
          .join("-"),
        date_before: dateBeforeFilter
          ?.split(" ")[0]
          .split(".")
          .reverse()
          .join("-"),
        ordering: getOrderBy(),
      };
    dispatch(getUrlKfeedHits({ filters, page: currentPage }));
  };
  const fetchDataGraph = () => {
    let filters = false;
    if (snFilter)
      filters = {
        serial_nr: snFilter,
      };
    dispatch(getUrlGraph({ filters }));
  };

  useEffect(() => {
    if (snFilterStore) {
      setSnFilter(snFilterStore);
      dispatch(setSnFilterUrl(null));
    }
  }, [snFilterStore]);



  const handleOpenChangeDate = () => setOpenDate(!openDate);

  useEffect(() => {
    const timerRefresh = setTimeout(fetchDataGraph, 500);
    return () => {
      clearTimeout(timerRefresh);
    };
  }, [snFilter]);

  useEffect(() => {
    const timerRefresh = setTimeout(fetchDataHits, 500);
    return () => {
      clearTimeout(timerRefresh);
    };
  }, [snFilter, dateAfterFilter, dateBeforeFilter, currentPage, ordering]);
  const dateParse = (dateStr) => {
    if (dateStr) {
      const dateParts = dateStr.split(" ");
      const date = dateParts[0].split(".").reverse().join("-");
      const time = dateParts[1];
      const timeZone = dateParts[3].slice(1, 2);
      let sign = "+";
      if (dateParts[3].includes("-")) {
        sign = "-";
      }
      if (timeZone >= 10) {
        const isoDateString = `${date}T${time}:00${sign}${timeZone}:00`;
        return isoDateString;
      } else {
        const isoDateString = `${date}T${time}:00${sign}0${timeZone}:00`;
        return isoDateString;
      }
    } else return null;
  };
  const handleColorDates = (date) => {
    if (date) {
      const today = new Date();
      const datefirstSeen = new Date(dateParse(date));
      const diffInDays = Math.floor(
        (today - datefirstSeen) / (1000 * 60 * 60 * 24)
      );
      if (Math.abs(diffInDays) < 30) {
        return <Badge count={"<30 дн"} showZero color="red" />;
      } else if (Math.abs(diffInDays) >= 30 && Math.abs(diffInDays) < 180) {
        return <Badge count={"<6 мес"} showZero color="orange" />;
      } else {
        return <Badge count={">6 мес"} showZero color="yellow" />;
      }
    } else return "Нет данных";
  };

  function checkPattern(str) {
    const regex = /^[^]{2}\*{0,}[^]{2}$/;
    return regex.test(str);
  }

  const columns = [
    createColumn(
      "URL",
      "url",
      3,
      false,
      (text) => {
        return (
          !checkPattern(text) ? (<div style={{ wordWrap: "break-word", width: "250px" }}>{text}</div>) :
            (<div style={{ display: "flex", gap: 5, overflowWrap: 'break-word', wordBreak: 'break-word', flexWrap: 'nowrap', width: "250px" }}>
              <div style={{ maxWidth: '235px' }}>
                {text}
              </div>
              <Tooltip
                color="#FFFFFF"
                overlayStyle={{ minWidth: 285 }}
                title={
                  <>
                    <span style={{ color: "#4B4F55", fontSize: 14 }}>
                      Приобретите лицензию, чтобы получить полный доступ к информации </span>

                  </>
                }
              >
                <InfoCircleOutlined style={{ fontSize: 10, color: '#7A7D86', cursor: 'pointer' }} />
              </Tooltip>
            </div>)

        );
      },
      false,
      () => {
        return 0;
      },
      ordering.url
    ),
    createColumn(
      "Запросы",
      "kfeed_hits",
      1,
      false,
      false,
      false,
      () => {
        return 0;
      },
      ordering.kfeed_hits
    ),
    createColumn(
      "Прямые запросы",
      "kfeed_direct_hits",
      1,
      false,
      false,
      false,
      () => {
        return 0;
      },
      ordering.kfeed_direct_hits
    ), createColumn(
      "Доп. запросы",
      "kfeed_secondary_hits",
      1,
      false,
      false,
      false,
      () => {
        return 0;
      },
      ordering.kfeed_secondary_hits
    ),
    createColumn(
      "Популярность",
      "popularity",
      1,
      false,
      false,
      false,
      () => {
        return 0;
      },
      ordering.popularity
    ),
    createColumn(
      "First seen",
      "first_seen",
      1,
      false,
      (text) => handleColorDates(text),
      false,
      () => {
        return 0;
      },
      ordering.first_seen
    ),

    createColumn("Детали", "kfeed_hits", 1, "center", (text, row) => (
      <EyeOutlined
        onClick={() => {
          SetUrl({ mask: row["mask"], item: row });
          setOpenModal(true);
        }}
        style={{ fontSize: "20px", color: "rgb(128, 144, 184)" }}
      />
    )),
  ];
  const handleChangeDateAfter = (e) => {
    if (e?.$d) {
      setDateAfterFilter(e?.$d.toLocaleString("ru-RU").replace(",", ""));
      if (dateBeforeFilter) {
        setViewDataFilter(
          e?.$d.toLocaleString("ru-RU").replace(",", "") +
          "-" +
          dateBeforeFilter
        );
      } else
        setViewDataFilter(
          e?.$d.toLocaleString("ru-RU").replace(",", "") + " - "
        );
    } else setDateAfterFilter(null);
  };
  const handleChangeDateBefore = (e) => {
    if (e?.$d) {
      setDateBeforeFilter(e?.$d.toLocaleString("ru-RU").replace(",", ""));
      if (dateAfterFilter) {
        setViewDataFilter(
          dateAfterFilter +
          " - " +
          e?.$d.toLocaleString("ru-RU").replace(",", "")
        );
      } else
        setViewDataFilter(
          " - " + e?.$d.toLocaleString("ru-RU").replace(",", "")
        );
    } else setDateBeforeFilter(null);
  };

  const frequency = {
    thirtyDaysAgo: {
      numberDate: new Date().setDate(new Date().getDate() - 30),
      title: "За последние 30 дней",
    },
    today: {
      numberDate: new Date().setHours(0, 0, 0, 0),
      title: "Сегодня",
    },

    yesterday: {
      numberDate: calculateYesterday(),
      title: "Вчера",
    },
    "1y": {
      numberDate: new Date().getTime() - 31536000000,
      title: "За последний год",
    },
  };

  const dateFormat = "YYYY-MM-DD";

  const resetDateFilterBefore = (value) => {
    const date =
      value?.split(" ")[0].split(".").reverse().join("-") +
      " " +
      value?.split(" ")[1];
    return dayjs(date, dateFormat);
  };
  const resetDateFilterAfter = (value) => {
    const date =
      value?.split(" ")[0]?.split(".")?.reverse().join("-") +
      " " +
      value?.split(" ")[1];
    return dayjs(date, dateFormat);
  };
  const frequentlyUsedFilters = [];

  Object.keys(frequency).forEach((key, i) => {
    frequentlyUsedFilters.push(
      <Button
        style={{ background: "transparent", border: "none" }}
        key={i}
        disabled={
          key === "1y" && !fallbacksData?.has_fallbacks_enabled ? true : false
        }
        onClick={() => {
          setCurrentPage(1);
          handleSetDateFilters(key);
        }}
      >
        {frequency[key].title}
      </Button>
    );
  });
  useEffect(() => {
    if (fallbacksData) {
      handleSetDateFilters("thirtyDaysAgo");
    }
  }, [fallbacksData]);

  const handleSetDateFilters = (value) => {
    if (frequency[value]) {
      setViewDataFilter(frequency[value].title);
      const actualDate = new Date(frequency[value].numberDate);
      setDateAfterFilter(actualDate.toLocaleString("ru-RU").replace(",", ""));
    }

    setOpenDate(false);
  };
  const content = (
    <List
      size="small"
      header={false}
      footer={
        <div>
          <hr />
          <Space direction="vertical">
            <span>Начало</span>
            <DatePicker
              renderExtraFooter={() => null}
              footer={null}
              format="DD-MM-YYYY"
              onChange={(e) => {
                setCurrentPage(1);
                handleChangeDateAfter(e);
              }}
              showNow={false}
              showToday={false}
              placeholder="Выберите дату начала"
              disabledDate={(d) => {
                return !fallbacksData?.has_fallbacks_enabled
                  ? !d || d.isBefore(calculateMonth()) || d.isAfter(new Date())
                  : !d || d.isBefore("2024-01-01") || d.isAfter(new Date());
              }}
              value={
                dateAfterFilter ? resetDateFilterAfter(dateAfterFilter) : null
              }
            />
            <span>Окончание</span>
            <DatePicker
              showNow={false}
              showToday={false}
              renderExtraFooter={() => null}
              format="DD-MM-YYYY"
              disabledDate={(d) => {
                return !fallbacksData?.has_fallbacks_enabled
                  ? !d || d.isBefore(calculateMonth()) || d.isAfter(new Date())
                  : !d || d.isBefore("2024-01-01") || d.isAfter(new Date());
              }}
              onChange={(e) => {
                setCurrentPage(1);
                handleChangeDateBefore(e);
              }}
              placeholder="Выберите дату окончания"
              value={
                dateBeforeFilter
                  ? resetDateFilterBefore(dateBeforeFilter)
                  : null
              }
            />
          </Space>
        </div>
      }
      className={styles.list}
      dataSource={frequentlyUsedFilters}
      renderItem={(item) => (
        <List.Item
          className={styles.listItem}
          style={{
            cursor: "pointer",
            border: "none",
          }}
        >
          {item}
        </List.Item>
      )}
    />
  );

  const handleSort = (field) => {
    let tempOrdering = {
      popularity: null,
      url: null,
      kfeed_hits: null,
      kfeed_direct_hits: null,
      kfeed_secondary_hits: null,
      first_seen: null,
    };

    switch (ordering[field]) {
      case "ascend":
        tempOrdering[field] = "descend";
        break;
      case "descend":
        tempOrdering[field] = null;
        break;
      default:
        tempOrdering[field] = "ascend";
    }

    setOrdering(tempOrdering);
  };
  const getOrderBy = () => {
    let orderBy = "";

    Object.keys(ordering).forEach((key) => {
      if (ordering[key] === "ascend") orderBy = key;
      if (ordering[key] === "descend") orderBy = "-" + key;
    });

    return orderBy;
  };

  const handleClearSearch = () => {
    setSnFilter('')
    setCurrentPage(1);
    setSnFilter(null);
  }


  const customLoader = (
    <Spin
      className={styles.kfeedTableLoader}
      style={{left: isSidebarCollapsed? '50%' : '60%'}}
      indicator={<PreloaderNew />}
    />
  );


  return (
    <Space
      direction="vertical"
      size={20}
      style={{ width: "100%", position: "relative" }}
    >
      {
        openModal && (
          <UrlItemModalKfeedResponse
            open={openModal}
            mask={url.mask}
            item={url.item}
            close={() => setOpenModal(false)}
          />
        )
      }

      {fallbacksData?.has_fallbacks_enabled === false &&
        fallbacksData?.has_kfeed_hits === true && (
          <Alert
            message={
              "Для использования полного функционала KFEED PLUGIN, необходимо приобрести лицензию. Обратитесь к своему менеджеру PARUS."
            }
            type="warning"
            showIcon
            icon={<IconWarning />}
            closable
          ></Alert>
        )}{" "}
      <Row justify="space-between" >
        <Col style={{ display: 'flex', gap: '15px', alignItems: 'flex-start' }} >
          <Select
            showSearch
            style={{
              width: 356,
              height: 32,
              color: 'black',
            }}
            allowClear={{
              clearIcon: <CloseOutlined
                style={{
                  color: 'red'
                }}
              />
            }}
            onClear={handleClearSearch}
            placeholder="Выберите SN"
            options={kfeedSerialsSelectOptions}
            value={snFilter}
            onSelect={(value) => {
              setSnFilter(value)
              setCurrentPage(1);
              setSnFilter(value);
            }}
          />
          <Space.Compact
            title="Выберите интервал"
          >
            <Popover
              placement="bottomLeft"
              open={openDate}
              onOpenChange={handleOpenChangeDate}
              title={false}
              content={content}
              trigger="click"
            >
              <Button
                className={styles.dashboardInput}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CalendarOutlined
                  style={{
                    padding: "5px 0",
                    color: "#6679A9",
                  }}
                />
                <span style={{ padding: "0 5px", fontSize: 12 }}>
                  {viewDataFilter}
                </span>
              </Button>
            </Popover>
          </Space.Compact>
        </Col>
        <Col>
          {(snFilter || viewDataFilter !== 'За последние 30 дней' || dateBeforeFilter) && (
            <Button
              type="default"
              onClick={() => {
                handleClearSearch()
                handleSetDateFilters("thirtyDaysAgo");
              }}
            >
              Сбросить
            </Button>
          )}
        </Col>

      </Row>
      <Row justify={"center"} gutter={8}>
        <Col span={24}>
          <Collapse
            size="small"
            expandIcon={({ isActive }) => <UpOutlined style={{ fontSize: 12, paddingTop: 4 }} rotate={isActive ? 180 : 0} />}
            style={{
              backgroundColor: 'white',
              alignContent: 'center',
              borderRadius: '8px',
              fontSize: 18,
              fontWeight: 600,
              minHeight: '60px',
              padding: 0
            }}
            ghost
            defaultActiveKey={[1]}
            items={[
              {
                key: 1,
                label: (
                  <div style={{ display: 'flex', justifyContent: 'space-between', padding: 0, alignSelf: 'center' }}>
                    <span>Кол-во запросов по kfeed/день за последние 30 дней</span>
                    <div style={{ display: 'flex', gap: 8, alignItems: 'center', justifyContent: 'center', border: '1px solid #E9EEF7', borderRadius: 8, height: 44, width: 230 }}>
                      <CalendarOutlined style={{ fontSize: 16, color: '#6679A9' }} />
                      <span style={{ fontSize: 14, color: '#4B4F55', fontWeight: 400 }} >{getDaysBorder()}</span>
                    </div>
                  </div>
                ),
                children: <Row span={24}><NewGraphKfeed /></Row>,
              },
            ]} />
        </Col>
      </Row>
      <Row gutter={[8, 8]} justify="center">
        <Col
          span={24}
          md={24}
          style={{ background: "white", borderRadius: "8px", padding: 0 }}
        >
          <div className={styles.tableContainer}>
          <Table
            loading={{ indicator: customLoader, spinning: isLoader }}
            columns={columns}
            locale={{
              triggerDesc: "сортировать по убыванию",
              triggerAsc: "сортировать по возрастанию",
              cancelSort: "по умолчанию",
            }}
            onHeaderRow={() => {
              return {
                onClick: (e) => {
                  switch (e.target.innerText) {
                    case "ПОПУЛЯРНОСТЬ":
                      handleSort("popularity");
                      break;
                    case "URL":
                      handleSort("url");
                      break;
                    case "ЗАПРОСЫ":
                      handleSort("kfeed_hits");
                      break;
                    case "ДОП. ЗАПРОСЫ":
                      handleSort("kfeed_secondary_hits");
                      break;
                    case "ПРЯМЫЕ ЗАПРОСЫ":
                      handleSort("kfeed_direct_hits");
                      break;
                    case "FIRST SEEN":
                      handleSort("first_seen");
                      break;

                  }
                },
              };
            }}
            size="small"
            rowKey={(row) => getRandomId(row.uuid)}
            pagination={{
              current: currentPage,
              position: "bottomRight",
              defaultPageSize: 100,
              showSizeChanger: false,
              total: kfeedHitsData?.count,
              onChange: (page) => setCurrentPage(page),
            }}
            style={{ overflowX: "auto" }}
            className={styles.table}
            dataSource={kfeedHitsData?.results || []}
          />
          </div>
        </Col>
      </Row>
    </Space>
  );
}

function createColumn(
  title,
  key,
  width,
  align,
  render,
  defaultSortOrder,
  sorter,
  sortOrder
) {
  return {
    title,
    key,
    dataIndex: key,
    width,
    align,
    render,
    defaultSortOrder,
    sorter,
    sortOrder,
  };
}
export default UrlKfeed;
