import React from "react";
import { useEffect, useState } from "react";
import styles from "./EdlStyles.module.css";
import {
  Alert,
  Button,
  ConfigProvider,
  Input,
  Select,
  Space,
  Table,
  Typography,
  Tabs,
  Badge,
  Spin
} from "antd";
import {
  IconBell,
  IconCopy,
  IconDelete,
  IconDownloadFile,
  IconMarkFalse,
  IconMarkTrue,
  IconSearch,
  IconWarning,
} from "../../icons";

import ModalChangePassword from "./ModalChangePassword";
import { useDispatch, useSelector } from "react-redux";
import WithoutAuthorization from "../../components/WithoutAuthorization";
import {
  clearEdlErrors,
  getCheckPlatformsLimit,
  getEdlList,
  getEdlUser,
  getFileList,
} from "../../store/edl";
import {
  getEdlCheckErrors,
  getEdlDataLists,
  getEdlDataUser,
  getEdlExternalSuccessAdd,
  getEdlLoaders,
  getEdlSuccessActivateList,
  getEdlSuccessAdd,
  getEdlSuccessAddList,
  getEdlSuccessDeleteList,
  getEdlWarningPlatform,
  getValidatorApplySuccess,
} from "../../store/edl/selectors";
import ModalConfirm from "./ModalConfirm";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import EdlTaskListNew from "./EdlTaskListNew";
import { IconPlus } from "../../icons/IconPlus";
import ModalAddList from "./ModalAddList";
import ModalListDetail from "./ModalListDetail";
import EdlSettingsNew from "./EdlSettingsNew";
import EdlExternalNew from "./EdlExternalNew";
import ModalWarnings from "./ModalWarnings";
import EdlLimitsNew from "./EdlLimitsNew";
import Documentation from "./Documentation";
import Api from "./Api";
import { getCollapsed } from "../../store/sidebar/selectors";

const { Title, Text, Link } = Typography;

function EdlAreaNew({ permissions }) {
  const dispatch = useDispatch();
  const [firstRender, setFirstRender] = useState(true);
  const data = useSelector(getEdlDataLists);
  const isLoading = useSelector(getEdlLoaders);
  const dataUser = useSelector(getEdlDataUser);
  const errors = useSelector(getEdlCheckErrors);
  const isSidebarCollapsed = useSelector(getCollapsed)
  const [tabs, setTabs] = useState([
    { key: "lists", label: "Списки" },
    { key: "external", label: "Источники" },
    { key: "limits", label: "Лимиты" },
    { key: "tasks", label: "Задачи" },
    { key: "settings", label: "Настройки" },
    { key: "documentation", label: "Документация" },
  ]);

  useEffect(() => {
    if (dataUser) {
      if (dataUser.is_trial === true)
        setTabs([
          { key: "lists", label: "Списки" },
          { key: "external", label: "Источники" },
          { key: "limits", label: "Лимиты" },
          { key: "tasks", label: "Задачи" },
          { key: "settings", label: "Настройки" },
          { key: "documentation", label: "Документация" },
        ]);
      else
        setTabs([
          { key: "lists", label: "Списки" },
          { key: "external", label: "Источники" },
          { key: "limits", label: "Лимиты" },
          { key: "tasks", label: "Задачи" },
          { key: "settings", label: "Настройки" },
          { key: "api", label: "API" },
          { key: "documentation", label: "Документация" },
        ]);
    }
  }, [dataUser]);

  const [selectedTab, setSelectedTab] = useState(tabs[0].key);
  const [modalChangePasswordOpen, setModalChangePasswordOpen] = useState(false);
  const [modalDetailOpen, setModalDetailOpen] = useState(false);
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  const [modalAddOpen, setModalAddOpen] = useState(false);
  const [modalWarningsOpen, setModalWarningsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [activeFilter, setActiveFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [page, setPage] = useState(1);

  const [copyText, setCopyText] = useState(false);
  const warningMessage = useSelector(getEdlWarningPlatform);
  const isSuccessActivate = useSelector(getEdlSuccessActivateList);
  const isSuccessDelete = useSelector(getEdlSuccessDeleteList);
  const [currentItem, setCurrentItem] = useState(null);
  const isSuccessAdd = useSelector(getEdlSuccessAddList);
  const addSuccess = useSelector(getEdlSuccessAdd);
  const isSuccessExternalAdd = useSelector(getEdlExternalSuccessAdd);
  const validatorsSuccess = useSelector(getValidatorApplySuccess);

  useEffect(() => setFirstRender(false), []);

  useEffect(() => {
    if (permissions?.full_access || permissions?.read_access) {
      dispatch(getEdlUser());
      dispatch(getCheckPlatformsLimit());
    }
  }, [permissions]);

  const fetchData = () => {
    if (
      !isSuccessActivate &&
      !isSuccessDelete &&
      isSuccessAdd === false &&
      !addSuccess &&
      !validatorsSuccess
    ) {
      let filters = false;
      if (searchText || activeFilter || typeFilter) {
        filters = {
          search: searchText,
          active: activeFilter
            ? activeFilter === "Активен"
              ? true
              : false
            : "",
          list_type: typeFilter ? typeFilter : "",
        };
      }
      if (!searchText) dispatch(getEdlUser());
      if (permissions?.full_access || permissions?.read_access) {
        dispatch(getEdlList({ listPage: page, filters: filters }));
      }
    }
  };

  useEffect(
    () => {
      const timerRefresh = setTimeout(fetchData, 500);
      return () => {
        clearTimeout(timerRefresh);
      };
    },
    [page, searchText, activeFilter, typeFilter],
    permissions
  );

  useEffect(() => {
    if (firstRender) return;
    fetchData();
  }, [
    isSuccessActivate,
    isSuccessDelete,
    isSuccessAdd,
    addSuccess,
    isSuccessExternalAdd,
    validatorsSuccess,
    permissions,
  ]);

  useEffect(() => {
    if (permissions?.full_access || permissions?.read_access) {
      dispatch(getEdlUser());
    }
  }, [dispatch]);

  const copyBufferTextTable = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopyText({ text: "Copy", field: "table" });
      })
      .catch(() => {
        setCopyText({ text: "Error", field: "table" });
      });
  };

  const getCheckColorValidator = (validator) => {
    let color;
    switch (validator) {
      case "PAN":
        color = "rgb(45, 183, 245, .5)";
        break;
      case "FORTINET":
        color = "rgb(0,255,255, .5)";
        break;
      case "CP":
        color = "rgb(128, 0, 128, .5)";
        break;
    }
    return (
      <div
        key={validator}
        style={{
          fontSize: "8px",
          background: `${color}`,
          borderRadius: "4px",
          padding: "2px",
        }}
      >
        {validator}
      </div>
    );
  };

  const getListTypeBadge = (type) => {
    let color;
    switch (type) {
      case "HASH_LIST":
        color = "rgb(255, 222, 173,  .5)";
        break;
      case "URL_LIST":
        color = "rgb(188, 143, 143,   .5)";
        break;
      case "IP_ADDRESS_LIST":
        color = "rgb(184, 134, 11,  .5)";
        break;
      case "DOMAIN_LIST":
        color = "rgb(154, 205, 50,  .5)";
        break;
    }
    return (
      <div
        key={type}
        style={{
          fontSize: "8px",
          background: `${color}`,
          borderRadius: "4px",
          padding: "2px",
          textAlign: "center",
        }}
      >
        {type.replace("_LIST", "")}
      </div>
    );
  };

  const columnsLists = [
    createColumn("", "warning", (text, row) => {
      return row["limits_exceeded_msg"] ? (
        <div
          title={row["limits_exceeded_msg"]}
          style={{
            padding: 0,
            color: "#faad14",
            width: "20px",
            height: "20px",
          }}
          onClick={() =>
            setModalWarningsOpen({
              open: true,
              data: [{ message: row["limits_exceeded_msg"] }],
              title: row["name"],
            })
          }
        >
          <IconWarning />
        </div>
      ) : (
        ""
      );
    }),
    createColumn("Название", "name", (name, row) => (
      <Space direction="vertical">
        <Space>
          <Link
            onClick={() => {
              setCurrentItem(row["id"]);
              setModalDetailOpen(true);
            }}
            style={{ fontSize: "12px" }}
            disabled={isLoading}
          >
            {name}
          </Link>
          {row["is_demo"] && (
            <div
              style={{
                fontSize: "8px",
                background: `rgb(0, 0, 128)`,
                borderRadius: "4px",
                padding: "2px",
                textAlign: "center",
                color: "white",
              }}
            >
              {"DEMO"}
            </div>
          )}
        </Space>
        <Space>
          {row["validators"].length > 0 ? (
            row["validators"]?.map((item) => getCheckColorValidator(item))
          ) : (
            <div
              style={{
                fontSize: "10px",
                color: "white",
                textAlign: "center",
                background: "rgb(128, 128, 128, .5)",
                borderRadius: "4px",
                padding: "2px",
                width: "70px",
              }}
            >
              No validation
            </div>
          )}
        </Space>
      </Space>
    )),
    {
      title: "Создан",
      key: `created`,
      dataIndex: ["date_created", "created_by"],
      render: (text, row) => {
        return (
          <div>
            <span>{row["date_created"]}</span>

            <div style={{ color: "#7A7D86" }}>{row["created_by"]}</div>
          </div>
        );
      },
    },
    {
      title: "Изменен",
      key: `modified`,
      dataIndex: ["date_modified", "updated_by"],
      render: (text, row) => {
        return (
          <div>
            <span>{row["date_modified"]}</span>

            <div style={{ color: "#7A7D86" }}>{row["updated_by"]}</div>
          </div>
        );
      },
    },
    createColumn("Тип", "list_type", (text) => getListTypeBadge(text)),
    createColumn("Кол-во Записей", "instance_count"),
    createColumn("URL", "list_url", (url, row) => (
      <>
        {" "}
        <Space>
          <Text
            style={{
              fontSize: "10px",
              textOverflow: "ellipsis",
              display: "inline-block",
              whiteSpace: "nowrap",
            }}
            title={url}
          >
            ...{url.slice(-35)}
          </Text>
          <Button
            size="small"
            type="text"
            icon={
              <IconCopy style={{ color: "#8090B8" }} disabled={isLoading} />
            }
            onClick={() => {
              copyBufferTextTable(url);
              setCurrentItem(row);
            }}
          />
        </Space>
        <br />
        <Text>
          {row["id"] === currentItem?.id && copyText.field === "table" ? (
            <span
              style={
                copyText === "Ошибка"
                  ? { color: "red", fontSize: "6px" }
                  : { color: "green", fontSize: "6px" }
              }
            >
              {copyText?.text}
            </span>
          ) : (
            <span></span>
          )}
        </Text>
      </>
    )),
    createColumn("Активен", "is_active", (text, row) => (
      <div
        style={{
          backgroundColor: row["is_active"] ? "#E0F6EA" : "#FFECEC",
          color: row["is_active"] ? "#219653" : "#EB5757",
          width: 24,
          height: 24,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 2,
        }}
        disabled={isLoading}
        onClick={() => {
          permissions?.full_access && dispatch(clearEdlErrors());
          permissions?.full_access &&
            setModalConfirmOpen({
              open: true,
              action: "switchActive",
              item: row,
            });
        }}
      >
        {row["is_active"] ? <IconMarkTrue /> : <IconMarkFalse />}
      </div>
    )),
    {
      title: "",
      key: `actions`,
      dataIndex: ["file", "id", "name"],
      render: (_, row) => {
        return (
          <Space>
            <Button
              size="small"
              type="text"
              disabled={isLoading}
              icon={<IconDownloadFile style={{ color: "#8090B8" }} />}
              onClick={() =>
                dispatch(
                  getFileList({
                    url: row["file"],
                    name: row["name"],
                    id: row["id"],
                  })
                )
              }
            />
            {permissions?.full_access && (
              <Button
                size="small"
                type="text"
                icon={
                  <IconDelete
                    style={{ color: "#8090B8" }}
                    disabled={isLoading}
                  />
                }
                onClick={() => {
                  dispatch(clearEdlErrors());
                  setModalConfirmOpen({
                    open: true,
                    action: "delete",
                    item: row,
                  });
                }}
              />
            )}
          </Space>
        );
      },
    },
  ];
  const clearFilters = () => {
    setSearchText("");
    setActiveFilter("");
    setTypeFilter("");
  };
  const customLoader = (
    <Spin className={styles.tableLoader} style={{left: isSidebarCollapsed? '50%' : '60%'}} indicator={<PreloaderNew />} />
  );

  return (
    <ConfigProvider
      theme={{
        components: {
          Alert: {
            defaultPadding: "12px 16px",
            colorText: "#4B4F55",
          },
          Button: {
            controlPaddingHorizontal: 16,
            controlHeight: 40,
            colorText: "#327FEF",
          },
          Select: {
            controlHeight: 40,
          },
          Input: {
            controlHeight: 40,
            paddingInline: 16,
          },
        },
      }}
    >
      {modalDetailOpen && (
        <ModalListDetail
          permissions={permissions}
          open={modalDetailOpen}
          close={() => setModalDetailOpen(false)}
          itemId={currentItem}
          user={dataUser}
        />
      )}
      {modalAddOpen && (
        <ModalAddList
          open={modalAddOpen}
          close={() => setModalAddOpen(false)}
        />
      )}
      {modalChangePasswordOpen && (
        <ModalChangePassword
          open={modalChangePasswordOpen}
          close={() => setModalChangePasswordOpen(false)}
        />
      )}

      {
        <ModalConfirm
          open={modalConfirmOpen?.open}
          close={() => setModalConfirmOpen(false)}
          action={modalConfirmOpen?.action}
          item={modalConfirmOpen.item}
        />
      }
      <ModalWarnings
        open={modalWarningsOpen?.open}
        close={() => setModalWarningsOpen(false)}
        data={modalWarningsOpen?.data}
        title={modalWarningsOpen?.title}
      />
      {(permissions?.full_access || permissions?.read_access) && (
        <Space data-testid = 'threatLists-area' direction="vertical" size={16} style={{ width: "100%" }}>
          <Space size={24}>
            <Title level={3} style={{ margin: 0 }}>
              THREAT LISTS
            </Title>

            {warningMessage?.length > 0 && (
              <Button
                type="ghost"
                style={{ paddingInline: 8 }}
                onClick={() =>
                  setModalWarningsOpen({ open: true, data: warningMessage })
                }
              >
                <Badge
                  count={warningMessage?.length}
                  color="orange"
                  offset={[-5, 5]}
                  size="small"
                  style={{ padding: "0 4px" }}
                >
                  <IconBell style={{ color: "#6679A9" }} />
                </Badge>
              </Button>
            )}
          </Space>
          {warningMessage?.length > 0 &&
            warningMessage?.map((item) => (
              <Alert
                message={item.message}
                type="warning"
                key={item.message + "error"}
                showIcon
                icon={<IconWarning />}
                closable
              >
                {item.message}
              </Alert>
            ))}
 {errors && (
        <Alert
          Alert
          message={errors}
          type="error"
          key={+"error"}

          closable
        ></Alert>
      )}
          <Tabs
            items={tabs}
            activeKey={selectedTab}
            className={styles.tabs}
            onChange={(key) => setSelectedTab(key)}
          />

          {selectedTab === "lists" && (
            <>
              {permissions?.full_access && (
                <div data-testid='add-button' style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    className={styles.addButton}
                    style={{
                      backgroundColor: "transparent",
                      // display: !true ? "none" : "",
                    }}
                    onClick={() => setModalAddOpen(true)}
                  >
                    <IconPlus />
                    Добавить
                  </Button>
                </div>
              )}
              <div
                data-testid='search-filters'
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr auto auto auto 1fr 140px",
                  gap: 12,
                  position: "relative",
                }}
              >
                
                <Input
                  prefix={<IconSearch style={{ color: "#C1C2C9" }} />}
                  value={searchText}
                  placeholder="Поиск"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
                <Select
                  onChange={(e) => setActiveFilter(e)}
                  defaultValue={""}
                  value={activeFilter}
                  options={[
                    { value: "", label: "Выберите статус" },
                    { value: "Активен", label: "Активен" },
                    { value: "Неактивен", label: "Неактивен" },
                  ]}
                />
                <Select
                  defaultValue={""}
                  value={typeFilter}
                  onChange={(e) => setTypeFilter(e)}
                  options={[
                    { value: "", label: "Выберите тип" },
                    { value: "DOMAIN_LIST", label: "DOMAIN" },
                    { value: "IP_ADDRESS_LIST", label: "IP ADRESS" },
                    { value: "URL_LIST", label: "URL" },
                    { value: "HASH_LIST", label: "HASH" },
                  ]}
                />
                {(activeFilter || searchText || typeFilter) && (
                  <Button
                    style={{
                      backgroundColor: "transparent",
                      // display: !true ? "none" : "",
                    }}
                    onClick={() => clearFilters()}
                  >
                    Сбросить все
                  </Button>
                )}
              </div>
              <Table
              loading={{
                indicator: customLoader,
                spinning: isLoading
              }}
                dataSource={data?.results}
                columns={columnsLists}
                className={styles.table}
                rowKey={(obj) => obj.id}
                rowClassName={(v) => (v.hightlight ? styles.rowHighlight : "")}
                size="small"
                style={{ position: "relative" }}
                pagination={{
                  showSizeChanger: false,
                  position: "bottomRight",
                  defaultPageSize: 10,
                  total: data?.count,
                  onChange: (page) => setPage(page),
                }}
              />
            </>
          )}
          {selectedTab === "external" && (
            <EdlExternalNew data-testid='edl-external-new' permissions={permissions} />
          )}
          {selectedTab === "settings" && (
            <EdlSettingsNew  data-testid='edl-settings-new' dataUser={dataUser} permissions={permissions} />
          )}
          {selectedTab === "tasks" && <EdlTaskListNew data-testid='edl-tasklist-new' />}
          {selectedTab === "limits" && <EdlLimitsNew data-testid='edl-limits-new'/>}
          {selectedTab === "api" && <Api data-testid='api'/>}
          {selectedTab === "documentation" && <Documentation data-testid='documentation'/>}
        </Space>
      )}
      {permissions?.full_access === false &&
        permissions?.read_access === false && <WithoutAuthorization />}
    </ConfigProvider>
  );
}

function createColumn(title, key, render) {
  return { title, key, dataIndex: key, render };
}

export default EdlAreaNew;
