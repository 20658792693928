import React, { useEffect, useState } from "react";
import { Space, Typography, message, Card, Alert, Button, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Sandbox.module.css";
import { Row, Col } from "antd";
import WithoutAuthorization from "../../components/WithoutAuthorization";
import SandboxList from "./SandboxList";
import SettingsDrawer from "./SettingsDrawer";
import {
  getSandboxMyAssets,
  getSandboxOverallStatistics,
  getGraphsData,
} from "../../store/sandbox";
import {
  getSandboxFileErrors,
  getSandboxLoaders,
  getSandboxOverallStats,
} from "../../store/sandbox/selectors";
import { IconSandboxSettings } from "../../icons/IconSandboxSettings";
import HashFileChecker from "./HashFileChecker";
import GraphsArea from "./GraphsArea";
import MetricsArea from "./MetricsArea";
import { getOrganisation } from "../../store/auth/selectors";
import StatsFiltersArea from "./StatisticsFilters";
import SandboxDocumentationArea from "./SandboxDocumentationArea";
const { Title } = Typography;

function SandboxArea({ permissions }) {
  const [, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState(1)
  const isLoader = useSelector(getSandboxLoaders);
  message.config({ duration: 5 });
  const fileError = useSelector(getSandboxFileErrors);
  const sandboxOverallStatistics = useSelector(getSandboxOverallStats);
  const org = useSelector(getOrganisation);


  //StatsAreaFilters

  const [statsSourceIn, setStatsSourceIn] = useState('00,01,02,03');
  const [statsPoint, setStatsPoint] = useState(null);
  const [statsValue, setStatsValue] = useState(null);
  const [statsDateAfterFilter, setStatsDateAfterFilter] = useState(null);
  const [statsDateBeforeFilter, setStatsDateBeforeFilter] = useState(null);


  const [openSettingsDrawer, setOpenSettingsDrawer] = useState(false)

  const fetchStatsData = () => {
    let filters;
    if (statsValue || statsDateAfterFilter || statsDateBeforeFilter || statsSourceIn) {
      filters = {
        source__in: statsValue === 'API' ? '01' : statsSourceIn,
        user_id__in: statsSourceIn === '00,03' || statsSourceIn === '01' ? null : (Array.isArray(statsValue) ? statsValue?.join(',') : statsValue),
        firewall_id: statsSourceIn === '02' || statsSourceIn === '01' ? null : (Array.isArray(statsValue) ? statsValue?.join(',') : statsValue),
        created_at__gte: statsDateAfterFilter ? formatDate(statsDateAfterFilter) : null,
        created_at__lte: statsDateBeforeFilter ? formatDate(statsDateBeforeFilter) : null,
      };
    }
    Object.keys(filters)?.forEach(key => {
      if (filters[key] === null || filters[key] === undefined || filters[key] == []) {
        delete filters[key]
      }
    })
    if (filters.source__in === '01' || (filters.source__in === '00,03' && statsValue !== null)
      || (filters.source__in === '02' && statsValue !== null
        // && value?.length>0
      ) || filters.source__in === '00,01,02,03') {
      if (permissions?.full_access || permissions?.read_access) {
        dispatch(getSandboxOverallStatistics({ filters }));
        dispatch(getGraphsData({ filters }));
      }
    }
  }


  const clearFilters = () => {
    setStatsPoint(null);
    setStatsValue(null);
    setStatsDateAfterFilter(null);
    setStatsSourceIn('00,01,02,03');
    setStatsDateBeforeFilter(null);

  }

  const handleTabChange = (key) => {
    setActiveKey(key)
  }

  const sandboxTabs = [
    {
      key: 1,
      label: 'Статистика',
      children: (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
          <HashFileChecker
            handleTabChange={handleTabChange}
          />
          {fileError && (
            <Alert message={fileError} type="error" key={"error"} showIcon>
              {fileError}
            </Alert>
          )}
          <StatsFiltersArea setStatsPoint={setStatsPoint}
            statsDateAfterFilter={statsDateAfterFilter}
            setStatsDateAfterFilter={setStatsDateAfterFilter}
            statsDateBeforeFilter={statsDateBeforeFilter}
            setStatsDateBeforeFilter={setStatsDateBeforeFilter}
            setStatsValue={setStatsValue}
            statsValue={statsValue}
            statsPoint={statsPoint}
            setStatsSourceIn={setStatsSourceIn}
            org={org}
            clearFilters={clearFilters}
            fetchStatsData={fetchStatsData}

          />
          <Card style={{ position: "relative" }}>
            <MetricsArea dataStats={sandboxOverallStatistics} />
          </Card>
          <GraphsArea />

        </div>)
    },
    {
      key: 2,
      label: 'Проверки',
      children: (<div style={{ opacity: isLoader ? 0.3 : 1 }}>

        <SandboxList
          permissions={permissions}
          org={org}
          activeKey={activeKey}
        />
      </div>)

    },
    {
      key: 3,
      label: 'Документация',
      children: (<div style={{ opacity: isLoader ? 0.3 : 1 }}>
        <SandboxDocumentationArea 
        activeKey={activeKey}
        />

      </div>)

    },
  ]


  function formatDate(input) {
    return input.split('.')[0];
  }
  useEffect(() => {
    if ((permissions?.full_access || permissions?.read_access)) {
      dispatch(getSandboxMyAssets());
      fetchStatsData()
    }
  }, [permissions]);





  useEffect(() => {
    if (permissions?.full_access || permissions?.read_access) {
      const timerRefresh = setTimeout(fetchStatsData, 600);
      return () => {
        clearTimeout(timerRefresh);
      };
    }
  }, [statsValue, statsDateAfterFilter, statsDateBeforeFilter])

  return (
    <Space direction="vertical" size={20} style={{ width: "100%" }}>
      {contextHolder}
      <Row>
        <Col span={12}>
          <Title level={3} style={{ margin: 0 }}>
            SANDBOX
          </Title>
        </Col>
        <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
          <Button disabled className={styles.settingsBtn} icon={<IconSandboxSettings />} onClick={() => setOpenSettingsDrawer(true)}>Настройки</Button>
        </Col>
      </Row>


      {
        (permissions?.full_access || permissions?.read_access) && (
          <>
            <SettingsDrawer openSettingsDrawer={openSettingsDrawer} setOpenSettingsDrawer={setOpenSettingsDrawer} />
            <Tabs data-testid='sandboxTabs' onChange={handleTabChange} className={styles.mainTabs} activeKey={activeKey} items={sandboxTabs} />
          </>
        )
      }
      {
        permissions?.full_access === false &&
        permissions?.read_access === false && <WithoutAuthorization />
      }
    </Space >
  );
}

export default SandboxArea;
